const routes = [
  {
    showOnNav: true,
    path: "/summaryReport",
    icon: "el-icon-s-data",
    name: "summaryReport",
    meta: {
      title: "汇总数据",
      page_name: "汇总数据",
    },
    component: () => import("../views/MCN/summaryReport.vue"),
  },
  {
    showOnNav: true,
    path: "/expertReport",
    icon: "el-icon-s-data",
    name: "expertReport",
    meta: {
      title: "达人数据",
      page_name: "达人数据",
    },
    component: () => import("../views/MCN/expertReport.vue"),
  },
  {
    showOnNav: true,
    path: "/investmentReport",
    icon: "el-icon-s-data",
    name: "investmentReport",
    meta: {
      title: "投流数据",
      page_name: "投流数据",
    },
    component: () => import("../views/MCN/investmentReport.vue"),
  },
  {
    showOnNav: true,
    path: "/goodsReport",
    icon: "el-icon-s-data",
    name: "goodsReport",
    meta: {
      title: "商品数据",
      page_name: "商品数据",
    },
    component: () => import("../views/MCN/goodsReport.vue"),
  },
  {
    showOnNav: true,
    path: "/profitReport",
    icon: "el-icon-s-data",
    name: "profitReport",
    meta: {
      title: "利润数据",
      page_name: "利润数据",
    },
    component: () => import("../views/MCN/profitReport.vue"),
  },
  // {
  //   showOnNav: true,
  //   path: "/dataBoard",
  //   icon: "el-icon-s-data",
  //   name: "dataBoard",
  //   meta: {
  //     title: "数据看板",
  //     page_name: "数据看板",
  //   },
  //   component: () => import("../views/MCN/dataBoard.vue"),
  // },
  {
    showOnNav: true,
    path: "/basic",
    icon: "el-icon-s-data",
    name: "basicDatas",
    meta: {
      title: "基础数据",
      page_name: "基础数据",
    },
    component: () => import("../views/MCN/basicDatas/index.vue"),
    children: [
      {
        showOnNav: true,
        path: "/liveAnalysis",
        icon: "",
        name: "liveAnalysis",
        meta: {
          title: "直播分析",
          page_name: "直播分析",
        },
        component: () => import("../views/MCN/basicDatas/liveAnalysis.vue"),
      },
      {
        showOnNav: true,
        path: "/expertAnalysis",
        icon: "",
        name: "expertAnalysis",
        meta: {
          title: "达人分析",
          page_name: "达人分析",
        },
        component: () => import("../views/MCN/basicDatas/expertAnalysis.vue"),
      },
      {
        showOnNav: true,
        path: "/videoAnalysis",
        icon: "",
        name: "videoAnalysis",
        meta: {
          title: "视频分析",
          page_name: "视频分析",
        },
        component: () => import("../views/MCN/basicDatas/videoAnalysis.vue"),
      },
      {
        showOnNav: true,
        path: "/goodsAnalysis",
        icon: "",
        name: "goodsAnalysis",
        meta: {
          title: "商品分析",
          page_name: "商品分析",
        },
        component: () => import("../views/MCN/basicDatas/goodsAnalysis.vue"),
      },
      {
        showOnNav: true,
        path: "/affiliatePartner",
        icon: "",
        name: "affiliatePartner",
        meta: {
          title: "Affiliate Partner",
          page_name: "Affiliate Partner",
        },
        component: () => import("../views/MCN/basicDatas/affiliatePartner.vue"),
      },
      {
        showOnNav: true,
        path: "/feeAgreement",
        icon: "",
        name: "feeAgreement",
        meta: {
          title: "费用协议",
          page_name: "费用协议",
        },
        component: () => import("../views/MCN/basicDatas/feeAgreement.vue"),
      },
      {
        showOnNav: true,
        path: "/creatorTransactionData",
        icon: "",
        name: "creatorTransactionData",
        meta: {
          title: "创作者交易数据",
          page_name: "创作者交易数据",
        },
        component: () => import("../views/MCN/basicDatas/creatorTransactionData.vue"),
      },
      {
        showOnNav: true,
        path: "/InvestmentData",
        icon: "",
        name: "InvestmentData",
        meta: {
          title: "投流分析",
          page_name: "投流分析",
        },
        component: () => import("../views/MCN/basicDatas/InvestmentData.vue"),
      },
      {
        showOnNav: true,
        path: "/expertSalary",
        icon: "",
        name: "expertSalary",
        meta: {
          title: "达人薪资",
          page_name: "达人薪资",
        },
        component: () => import("../views/MCN/basicDatas/expertSalary/list.vue"),
      },
      {
        showOnNav: true,
        path: "/profitGoal",
        icon: "",
        name: "profitGoal",
        meta: {
          title: "利润目标",
          page_name: "利润目标",
        },
        component: () => import("../views/MCN/basicDatas/profitGoal/list.vue"),
      },
    ],
  },
];
export default routes;
