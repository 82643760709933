import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI from "element-ui";
import axios_ from "@/assets/js/axios";

import "@/assets/css/reset.css";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI, { size: "medium" });

import VTooltip from "v-tooltip";
Vue.use(VTooltip);

import lodash from "lodash";
Vue.prototype.$lodash = lodash;

import { message } from "@/assets/js/message.js";
Vue.prototype.$message = message;

import VueCookies from "vue-cookie";
Vue.use(VueCookies);

import "@/assets/css/main.scss";

Vue.config.productionTip = false;

let base_url = process.env.VUE_APP_BASE_API;

Vue.prototype.$baseUrl = base_url + "api/hsp/";
Vue.prototype.$pythonHost = base_url + "phs/";

import common from "@/assets/js/common";
Vue.prototype.common = common;
Vue.prototype.$cookies = VueCookies;

Vue.prototype.axios = axios_;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
