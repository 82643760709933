<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {
      isRouterAlive: true,
    };
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>
<style lang="scss">
.setClassifyPopover {
  padding: 30px !important;
  width: 500px !important;
  .el-checkbox-group {
    max-height: 400px;
    overflow-y: scroll;
    overflow-x: hidden;
    border-bottom: 1px solid #d7dae2;
    margin-bottom: 20px;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    .el-checkbox {
      display: inline-flex;
      align-items: center;
      margin-right: 19px;
      margin-bottom: 20px;
      .el-checkbox__label {
        width: 130px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
  .btns {
    text-align: center;
  }
}
</style>
