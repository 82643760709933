const login = () => import("../views/login/login.vue");
const EDI = () => import("../views/commonViews/EDI.vue");
const Layout = () => import("../views/layout/Index.vue");
const routes = [
  {
    path: "/",
    name: "login",
    meta: {
      title: "登录",
    },
    component: login,
    showOnNav: false,
  },
  {
    path: "/edi",
    name: "edi",
    meta: {
      title: "EDI",
    },
    component: EDI,
    showOnNav: false,
  },
  {
    path: "/layout",
    name: "Layout",
    component: Layout,
    showOnNav: false,
    children: [],
  },
];
export default routes;
