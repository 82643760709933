import axios from "axios";
import router from "@/router/index";
import { message } from "@/assets/js/message.js";
import VueCookies from "vue-cookie";

const sleep = (ms = 1000) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

// while token expired
const tokenExpired = function () {
  localStorage.clear();
  VueCookies.delete("hotpots_token_from_rocket");
  message.error("登录过期，请重新登录");
  router.push("/");
};
// get config_data
const getFilterData = function () {
  axios.defaults.headers.common["token"] = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.VUE_APP_BASE_API}bmp/api/v1/config/datas`, {})
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const getAjaxFollowers = function () {
  axios.defaults.headers.common["token"] = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.VUE_APP_BASE_API}api/hsp/v1/TiktokSearch/getFollowers`, {})
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const getFollowers = async function () {
  let followers = [];
  if (localStorage.getItem("followers")) {
    followers = JSON.parse(localStorage.getItem("followers"));
  } else {
    let res = await getAjaxFollowers();
    if (res.data.code === 1) {
      localStorage.setItem("followers", JSON.stringify(res.data.data));
      followers = res.data.data;
    } else {
      message({
        message: res.data.msg,
        type: "error",
      });
    }
  }
  return followers;
};

const getAjaxCountrys = function () {
  axios.defaults.headers.common["token"] = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.VUE_APP_BASE_API}api/hsp/v1/getCountry`, {})
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const getCountrys = async function () {
  let countrys = [];
  if (localStorage.getItem("countrys")) {
    countrys = JSON.parse(localStorage.getItem("countrys"));
  } else {
    let res = await getAjaxCountrys();
    if (res.data.code === 1) {
      localStorage.setItem("countrys", JSON.stringify(res.data.data));
      countrys = res.data.data;
    } else {
      message({
        message: res.data.msg,
        type: "error",
      });
    }
  }
  return countrys;
};

const getFbAjaxLabels = function () {
  axios.defaults.headers.common["token"] = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.VUE_APP_BASE_API}api/hsp/v1/facebook/getFbLabels`, {})
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const getFbLabels = async function () {
  let categories = [];
  if (localStorage.getItem("categories")) {
    categories = JSON.parse(localStorage.getItem("categories"));
  } else {
    let res = await getFbAjaxLabels();
    if (res.data.code === 1) {
      localStorage.setItem("categories", JSON.stringify(res.data.data));
      categories = res.data.data;
    } else {
      message({
        message: res.data.msg,
        type: "error",
      });
    }
  }
  return categories;
};

const constConfigurationData = function () {
  return localStorage.getItem("configurationData") ? localStorage.getItem("configurationData") : {};
};

const outLink = function () {
  window.open("http://beian.miit.gov.cn/");
};
const inLink = function () {
  window.open("/edi");
};
const copyRightLink = function () {
  window.open("http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11030102011100");
};
const _copy = function (text) {
  var oInput = document.createElement("input");
  oInput.value = text;
  document.body.appendChild(oInput);
  oInput.select(); // 选择对象
  document.execCommand("Copy"); // 执行浏览器复制命令
  oInput.style.display = "none";
  message({
    message: "复制成功",
    type: "success",
  });
};
const windowOpen = function (url) {
  if (url.indexOf("http") > -1) {
    window.open(url);
  } else {
    window.open(`https://${url}`);
  }
};
const formatNumber = function (value, n = 2) {
  // 尝试将value转换为数字
  const num = Number(value);

  // 检查num是否是一个有效的数字
  if (!isNaN(num)) {
    // 使用国际化数字格式化对象来格式化数字
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: n,
      maximumFractionDigits: n,
    }).format(num);
  } else {
    // 如果num不是数字，返回原始的value
    return value;
  }
};
export default {
  outLink,
  inLink,
  copyRightLink,
  tokenExpired,
  getFilterData,
  constConfigurationData,
  _copy,
  windowOpen,
  getAjaxCountrys,
  getCountrys,
  getFbAjaxLabels,
  getFbLabels,
  getAjaxFollowers,
  getFollowers,
  formatNumber,
  sleep
};
