import axios from "axios";
import router from "@/router/index";
import { MessageBox } from "element-ui";
import { message } from "@/assets/js/message.js";
import VueCookies from "vue-cookie";

// while token expired
const tokenExpired = function () {
  localStorage.clear();
  VueCookies.delete("hotpots_token_from_rocket");
  message.error("登录过期，请重新登录");
  router.push("/");
};
const service = axios.create({
  timeout: 1000 * 60 * 5,
});

let cancelTokenSources = {};

// request拦截器
service.interceptors.request.use(
  (config) => {
    // 取消之前的相同请求
    const key = `${config.method}:${config.url}`;
    if (cancelTokenSources[key]) {
      cancelTokenSources[key].cancel("Canceled due to new request");
    }
    // 为新请求创建CancelToken
    config.cancelToken = new axios.CancelToken((cancel) => {
      cancelTokenSources[key] = { cancel };
    });
    config.headers["token"] = localStorage.getItem("token") || "";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// 响应拦截器
service.interceptors.response.use(
  (response) => {
    var { msg, code } = response.data;
    if (code == 20001) {
      tokenExpired();
    } else if (code === 1) {
      return Promise.resolve(response);
    } else {
      message({
        message: msg,
        type: "error",
      });
      return Promise.reject(response);
    }
  },
  (error) => {
    if (error.code == "ERR_CANCELED") {
      return;
    }
    let message = "请求错误";
    if (error.response) {
      // 服务器返回了不在2xx的范围的错误状态码
      message = handleHttpErrorStatus(
        error.response.status,
        error.response.data
      );
    } else if (error.request) {
      message = "网络请求未响应，请检查您的网络连接";
    } else {
      message = error.message;
    }
    MessageBox.alert(message, "警告", {
      type: "error",
      confirmButtonText: "知道了",
      callback: (action) => {},
    });
    console.error("Axios Response Error:", error);

    return Promise.reject(error);
  }
);

function handleHttpErrorStatus(status, data) {
  // 根据HTTP状态码和后端返回的数据来定制错误信息
  switch (status) {
    case 400:
      return "请求参数错误";
    case 401:
      return "未授权，请登录";
    case 403:
      return "拒绝访问";
    case 404:
      return "请求地址出错";
    case 408:
      return "请求超时";
    case 500:
      return "服务器内部错误";
    case 501:
      return "服务未实现";
    case 502:
      return "网关错误";
    case 503:
      return "服务不可用";
    case 504:
      return "网关超时";
    case 505:
      return "HTTP版本不受支持";
    default:
      return `连接出错! ERROR CODE: ${status}`;
  }
}

var axios_ = function (method, url, params = {}) {
  const key = `${method}:${url}`;
  // 取消之前的请求
  if (cancelTokenSources[key]) {
    cancelTokenSources[key].cancel("Canceled due to new request");
  }
  // 创建新的CancelToken
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  cancelTokenSources[key] = source;

  if (method.toLowerCase() == "get") {
    return new Promise((resolve, reject) => {
      service
        .get(url, {
          params: params,
          cancelToken: source.token,
        })
        .then((response) => {
          if (response?.data) {
            resolve(response.data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  } else {
    return new Promise((resolve, reject) => {
      service({
        method: method,
        url: url,
        data: params,
        cancelToken: source.token,
      })
        .then((response) => {
          if (response?.data) {
            resolve(response.data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};

export default axios_;
