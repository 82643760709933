const classifySetting = () => import("../views/settingViews/classifySetting/classifySetting.vue");
const fbSetting = () => import("../views/settingViews/classifySetting/fbSetting.vue");
const ttSetting = () => import("../views/settingViews/classifySetting/ttSetting.vue");
const ytSetting = () => import("../views/settingViews/classifySetting/ytSetting.vue");

const searchSetting = () => import("../views/settingViews/searchSetting/searchSetting.vue");
const fbSearchSetting = () => import("../views/settingViews/searchSetting/fbSearchSetting.vue");
const ttSearchSetting = () => import("../views/settingViews/searchSetting/ttSearchSetting.vue");
const ytSearchSetting = () => import("../views/settingViews/searchSetting/ytSearchSetting.vue");
const twitterSearchSetting = () => import("../views/settingViews/searchSetting/twitterSearchSetting.vue");

const routes = [
  {
    showOnNav: true,
    path: "/classifySetting",
    name: "classifySetting",
    icon: "iconfont icon-fenlei",
    meta: {
      title: "达人分类设置",
      page_name: "达人分类设置",
    },
    component: classifySetting,
  },
  {
    showOnNav: false,
    path: "/fbSetting",
    name: "fbSetting",
    icon: "iconfont icon-facebook-fill",
    meta: {
      title: "Facebook分类设置",
      page_name: "Facebook分类设置",
    },
    component: fbSetting,
  },
  {
    showOnNav: false,
    path: "/ttSetting",
    name: "ttSetting",
    icon: "iconfont icon-icon_TikTok",
    meta: {
      title: "TikTok分类设置",
      page_name: "TikTok分类设置",
    },
    component: ttSetting,
  },
  {
    showOnNav: false,
    path: "/ytSetting",
    name: "ytSetting",
    icon: "iconfont icon-youtube",
    meta: {
      title: "YouTube分类设置",
      page_name: "YouTube分类设置",
    },
    component: ytSetting,
  },
  {
    showOnNav: true,
    path: "/searchSetting",
    name: "searchSetting",
    icon: "iconfont icon-shezhixitongshezhigongnengshezhishuxing",
    meta: {
      title: "搜索设置",
      page_name: "搜索设置",
    },
    component: searchSetting,
  },
  {
    showOnNav: false,
    path: "/fbSearchSetting",
    name: "fbSearchSetting",
    icon: "iconfont icon-facebook-fill",
    meta: {
      title: "Facebook搜索设置",
      page_name: "Facebook搜索设置",
    },
    component: fbSearchSetting,
  },
  {
    showOnNav: false,
    path: "/ttSearchSetting",
    name: "ttSearchSetting",
    icon: "iconfont icon-icon_TikTok",
    meta: {
      title: "TikTok搜索设置",
      page_name: "TikTok搜索设置",
    },
    component: ttSearchSetting,
  },
  {
    showOnNav: false,
    path: "/ytSearchSetting",
    name: "ytSearchSetting",
    icon: "iconfont icon-youtube",
    meta: {
      title: "YouTube搜索设置",
      page_name: "YouTube搜索设置",
    },
    component: ytSearchSetting,
  },
  {
    showOnNav: false,
    path: "/twitterSearchSetting",
    name: "twitterSearchSetting",
    icon: "iconfont icon-tuite",
    meta: {
      title: "Twitter搜索设置",
      page_name: "Twitter搜索设置",
    },
    component: twitterSearchSetting,
  },
];
export default routes;
