import Vue from "vue";
import VueRouter from "vue-router";
import mediaPaths from "./routers";
import settingPaths from "./settingRouters";
import noNavPaths from "./noNavRouters";
import mcnRouters from "./mcnRouters";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);

let routes = [];
let pageType = localStorage.getItem("pageType"); // menu type in header_comp
let layout_index = noNavPaths.findIndex((item) => item.name === "Layout");

if (layout_index > -1) {
  let paths =
    pageType === "1"
      ? mediaPaths
      : pageType === "2"
      ? settingPaths
      : mcnRouters;
  noNavPaths[layout_index].children = paths;
}
routes = [...noNavPaths];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.path === "/login" || to.path === "/") {
    next();
  } else {
    let token = localStorage.getItem("token");
    if (token) {
      next();
    } else {
      next("/");
    }
  }
});
export default router;
