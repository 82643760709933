const facebookSearch = () => import("../views/searchManage/facebookSearch.vue");
const tiktokSearch = () => import("../views/searchManage/tiktokSearch.vue");
const youtubeSearch = () => import("../views/searchManage/youtubeSearch.vue");
const twitterSearch = () => import("../views/searchManage/twitterSearch.vue");

const routes = [
  {
    showOnNav: true,
    path: "/facebookSearch",
    icon: "iconfont icon-facebook-fill",
    name: "facebookSearch",
    index: "2",
    meta: {
      title: "Facebook 搜索",
      page_name: "Facebook",
    },
    component: facebookSearch,
  },
  {
    showOnNav: true,
    path: "/tiktokSearch",
    name: "tiktokSearch",
    icon: "iconfont icon-icon_TikTok",
    index: "1",
    meta: {
      title: "Tiktok 搜索",
      page_name: "Tiktok",
    },
    component: tiktokSearch,
  },
  {
    showOnNav: true,
    path: "/youtubeSearch",
    name: "youtubeSearch",
    icon: "iconfont icon-youtube",
    index: "3",
    meta: {
      title: "YouTube 搜索",
      page_name: "YouTube",
    },
    component: youtubeSearch,
  },
  {
    showOnNav: true,
    path: "/twitterSearch",
    name: "twitterSearch",
    icon: "iconfont icon-tuite",
    index: "3",
    meta: {
      title: "Twitter 搜索",
      page_name: "Twitter",
    },
    component: twitterSearch,
  }
];
export default routes;
